<!-- Loader -->
<div id="preloader" *ngIf="isLoading">
  <div id="status">
    <div class="spinner"></div>
  </div>
</div>
<!-- light-dark mode -->
<a href="javascript: void(0);" id="light-dark-mode" class="mode-btn text-white rounded-end" (click)="changeMode()">
  <i class="mdi mdi-sun-compass bx-spin mode-light"></i>
  <i class="mdi mdi-moon-waning-crescent mode-dark"></i>
</a>
<router-outlet></router-outlet>