<!-- Contact Us Start -->
<section class="section bg-light" id="contact">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <h3 class="title mb-3">Contact Us</h3>
          <p class="text-muted font-size-15">Et harum quidem rerum facilis est et expedita distinctio nam libero tempore
            cum soluta nobis eligendi cumque.</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-7 align-self-center">
        <div class="custom-form mb-5 mb-lg-0">
          <div id="message"></div>
          <form method="post" (ngSubmit)="createJob()" [formGroup]="itemData">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="name">Name*</label>
                  <input id="name" type="text" class="form-control" autocomplete="off" placeholder="Your name..." formControlName="name"  [ngClass]="{ 'is-invalid': submitted && form['name'].errors }" required>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="email">Email Address*</label>
                  <input id="email" type="email" class="form-control" autocomplete="off" placeholder="Your email..." formControlName="email" [ngClass]="{ 'is-invalid': submitted && form['email'].errors }" required>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <label for="comments">Message*</label>
                  <textarea id="comments" rows="4" class="form-control" placeholder="Your message..." formControlName="subject" [ngClass]="{ 'is-invalid': submitted && form['subject'].errors }" required></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <button type="button" class="btn btn-primary">Send Message<i-feather class="icon-size-15 ms-2 icon"
                    name="send"></i-feather></button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-lg-5 align-self-center">
        <div class="contact-detail text-muted ms-lg-5">
          <p class="">
            <i-feather class="icon-xs icon me-1" name="mail"></i-feather> : <span>support@website.com</span>
          </p>
          <p class="">
            <i-feather class="icon-xs icon me-1" name="link"></i-feather> : <span>www.website.com</span>
          </p>
          <p class="">
            <i-feather class="icon-xs icon me-1" name="phone-call"></i-feather> : <span>(+001) 123 456 7890</span>
          </p>
          <p class="">
            <i-feather class="icon-xs icon me-1" name="clock"></i-feather> : <span>9:00 AM - 6:00 PM</span>
          </p>
          <p class="">
            <i-feather class="icon-xs icon me-1" name="map-pin"></i-feather> : <span>1644 Deer Ridge Drive Rochelle
              Park, NJ 07662</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Contact Us End -->
